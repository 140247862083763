import classNames from 'classnames/bind'

import styles from './Button.module.scss'
import { BTN_SIZES, BTN_TYPES } from '@/constants'
import { BtnSize, BtnType } from '@/ts'

const cx = classNames.bind(styles)

interface IButton {
  className?: string
  type?: BtnType
  size?: BtnSize
  image?: string
  children: JSX.Element | string
  arrow?: boolean
  full?: boolean
  id?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Button = ({ className, type, size, onClick, children, full, id, disabled }: IButton) => {
  type = type ?? BTN_TYPES.PRIMARY
  size = size ?? BTN_SIZES.MEDIUM

  return (
    <button
      className={cx('btn', { 'btn-full': full }, type, ...size.split(' '), { 'btn-disabled': disabled }, className)}
      onClick={onClick}
      id={id}
    >
      {children}
    </button>
  )
}
