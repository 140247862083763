import { UNISWAP_ROUTER_02_ABI, UNISWAP_ROUTER_O2_CONTRACT, WETH_CONTRACT } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import { uint256ToBn } from '@/utils'
import BigNumber from 'bignumber.js'
import { useReadContracts } from 'wagmi'
import { StakingOptionPrice, StakingOptionPriceWithPid } from '@/ts'
import { useEthUsdPrice } from '@/hooks/use-eth-usd-price'

export const useAllSingleTokensPrice = () => {
  const { ethPriceUsd } = useEthUsdPrice()
  const oneTokenBigInt = BigInt(new BigNumber(10).pow(18).toString())
  const stakingPools_OnlyTokens = stakingPools.filter((pool) => !pool.lp?.isLpToken)

  const callsList = stakingPools_OnlyTokens.map((pool) => ({
    abi: UNISWAP_ROUTER_02_ABI,
    address: UNISWAP_ROUTER_O2_CONTRACT,
    functionName: 'getAmountsOut',
    args: [oneTokenBigInt, [pool.contract, WETH_CONTRACT]],
  }))

  const result = useReadContracts({
    contracts: callsList,
  })

  const allResultsPrice =
    result.data?.map((res, index) => {
      const data: StakingOptionPriceWithPid = {
        pid: stakingPools_OnlyTokens[index].PID,
        ethPrice: null,
        usdPrice: null,
      }

      if (res.result == null) return data

      const ethPrice = uint256ToBn((res.result as [bigint, bigint]) ?? ['0', '0'])
      const usdPrice = ethPriceUsd == null ? null : ethPrice.multipliedBy(ethPriceUsd)

      data.ethPrice = ethPrice
      data.usdPrice = usdPrice

      return data
    }) ?? []

  const allSingleTokensPriceList: StakingOptionPriceWithPid[] = stakingPools_OnlyTokens.map((pool, index) => {
    const price = allResultsPrice.find((res) => res.pid === pool.PID)
    return { pid: pool.PID, ethPrice: price?.ethPrice ?? null, usdPrice: price?.usdPrice ?? null }
  })

  const allSingleTokensPrice = allSingleTokensPriceList.reduce(
    (acc, curr) => {
      acc[curr.pid] = { ethPrice: curr.ethPrice, usdPrice: curr.usdPrice }
      return acc
    },
    {} as Record<number, StakingOptionPrice>
  )

  return { allSingleTokensPrice }
}
