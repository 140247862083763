import { SelectWalletConnection } from '@/components/SelectWalletConnection/SelectWalletConnection'
import { useWildcardSettings } from '../use-wildcard-settings'

export const useShowSelectWalletConnectionPrompt = () => {
  const { setWildcardSettings } = useWildcardSettings()

  const showSelectWalletConnectionPrompt = () => {
    setWildcardSettings({
      display: true,
      componentName: 'SelectWalletConnection',
      Component: <SelectWalletConnection />,
      background: 'normal',
    })
  }

  return { showSelectWalletConnectionPrompt }
}
