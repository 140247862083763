import { useReducer } from 'react'
import { type WildcardReducerAction, type WildcardSettingsInt } from '@/ts/WildcardSettings/WildcardSettingsInt'
import { defaultWildcardSettings } from '@/constants/default-wildcard-settings'

const wildcardReducer = (state: WildcardSettingsInt, action: WildcardReducerAction): WildcardSettingsInt => {
  let newState: WildcardSettingsInt

  switch (action.type) {
    case 'setWildcardSettings':
      newState = {
        settings: {
          ...state.settings,
          ...action.payload,
        },
        importTokenBuffer: [...state.importTokenBuffer],
      }
      action.storeSetter(newState)
      return newState

    case 'setImportTokenBuffer':
      newState = {
        settings: {
          ...state.settings,
        },
        importTokenBuffer: [...action.payload],
      }
      action.storeSetter(newState)
      return newState

    case 'addImportTokenBuffer':
      newState = {
        settings: {
          ...state.settings,
        },
        importTokenBuffer: [...state.importTokenBuffer, ...action.payload],
      }
      action.storeSetter(newState)
      return newState

    case 'setWildcardSettingsAndITBuffer':
      newState = {
        settings: {
          ...state.settings,
          ...action.payload.settings,
        },
        importTokenBuffer: [...action.payload.importTokenBuffer],
      }
      action.storeSetter(newState)
      return newState

    case 'forceWildcard':
      newState = {
        settings: {
          ...state.settings,
          forceWildcard: true,
        },
        importTokenBuffer: [...state.importTokenBuffer],
      }
      action.storeSetter(newState)
      return newState

    case 'unforceWildcard':
      newState = {
        settings: {
          ...state.settings,
          forceWildcard: false,
        },
        importTokenBuffer: [...state.importTokenBuffer],
      }
      action.storeSetter(newState)
      return newState

    case 'closeWildcard':
      if (state.settings.forceWildcard != null && state.settings?.forceWildcard) {
        if (action.payload?.ignoreForceWildcard != null && !action.payload?.ignoreForceWildcard) return { ...state }
      }

      if (state.importTokenBuffer?.length === 0) {
        newState = { ...defaultWildcardSettings }
        action.storeSetter(newState)
        return newState
      }

      newState = {
        settings: {
          ...state.importTokenBuffer[0],
        },
        importTokenBuffer: [...state.importTokenBuffer.slice(1)],
      }
      action.storeSetter(newState)
      return newState

    case 'setFromImportBuffer':
      if (state.settings.display || state.importTokenBuffer.length === 0) return { ...state }
      newState = {
        settings: {
          ...state.importTokenBuffer[0],
        },
        importTokenBuffer: [...state.importTokenBuffer.slice(1)],
      }
      action.storeSetter(newState)
      return newState

    default:
      return {
        ...state,
      }
  }
}

export const useWildcardReducer = (initialState: WildcardSettingsInt) => {
  return useReducer(wildcardReducer, initialState)
}
