import { EnforceEthChain } from '@/components/EnforceEthChain/EnforceEthChain'
import { useWildcardSettings } from '../use-wildcard-settings'

export const useShowEnforceEthChainPrompt = () => {
  const { setWildcardSettings } = useWildcardSettings()

  const showEnforceEthChainPrompt = () => {
    setWildcardSettings({
      display: true,
      componentName: 'EnforceEthChain',
      Component: <EnforceEthChain />,
      background: 'normal',
      forceWildcard: true,
    })
  }

  return { showEnforceEthChainPrompt }
}
