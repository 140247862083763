import { QueryClientProvider } from '@tanstack/react-query'
import { type FC } from 'react'
import { kumaBreederQueryClient } from './query-client'
import { Provider } from 'jotai'
import { kumaBreederStore } from './store'
import { HidrateKumaBreederAtoms } from './hidrate-atoms'
import { WagmiProvider } from 'wagmi'
import { wagmiConfig } from '@/constants'

interface ProviderProps {
  children: JSX.Element
}

export const KumaBreederProvider: FC<ProviderProps> = ({ children }) => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={kumaBreederQueryClient}>
        <Provider store={kumaBreederStore}>
          <HidrateKumaBreederAtoms>{children}</HidrateKumaBreederAtoms>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
