import './Loader.scss'

import { type FC } from 'react'

interface LoaderProps {
  size?: string
}

export const Loader: FC<LoaderProps> = ({ size }) => {
  const finalSize = size ?? '1rem'
  return <div className="loader" style={{ width: finalSize, height: finalSize }}></div>
}
