import { AllowedPool } from '@/ts'
import { useStakingPoolsUserInfo } from '../pools-info/use-staking-pools-user-info'
import { useAllStakingPoolsAllowance } from '../pools-info'

export const useRefetchInfoAfterTransaction = (PID: AllowedPool) => {
  const { refetchUserInfo } = useStakingPoolsUserInfo(PID)
  const { refetchAllStakingPoolsAllowance } = useAllStakingPoolsAllowance()

  const refetchInfoAfterTransaction = () => {
    refetchUserInfo()
    refetchAllStakingPoolsAllowance()
  }

  return { refetchInfoAfterTransaction }
}
