import { useAllStakingOptionsStakedAmount } from './use-all-staking-options-staked-amount'
import { useAllStakingOptionsPrice } from './staking-options-price'
import { stakingPools } from '@/constants/stakingPools'
import BigNumber from 'bignumber.js'
import { calculateFarmApr } from '@/utils'

export const useStakingPoolsInfo = () => {
  const { allStakingOptionsStakedAmounts } = useAllStakingOptionsStakedAmount()
  const { allStakingOptionsPrice } = useAllStakingOptionsPrice()
  const dKumaPrice = allStakingOptionsPrice[0]

  const newStakingPools = stakingPools.map((currPool) => {
    const poolTokenStakedAmount = allStakingOptionsStakedAmounts[currPool.PID]
    const poolTokenPrice = allStakingOptionsPrice[currPool.PID]

    const poolEthValue =
      poolTokenStakedAmount != null && poolTokenPrice.ethPrice != null
        ? poolTokenStakedAmount.multipliedBy(poolTokenPrice.ethPrice)
        : null
    const poolUsdValue =
      poolTokenStakedAmount != null && poolTokenPrice.usdPrice != null
        ? poolTokenStakedAmount.multipliedBy(poolTokenPrice.usdPrice)
        : null

    let apr: BigNumber | null = null
    if (poolEthValue?.isFinite() && poolEthValue.isGreaterThan(0) && dKumaPrice.ethPrice?.isFinite()) {
      apr = calculateFarmApr(currPool.allocPoint, dKumaPrice.ethPrice, poolEthValue)
    }

    return {
      ...currPool,
      apr,
      total: {
        staked: poolTokenStakedAmount,
        value: poolUsdValue,
      },
      pricePerUnit: poolTokenPrice.usdPrice,
    }
  })

  return { stakingPoolsInfo: newStakingPools }
}
