import {
  ethUsdPriceAtom,
  uniswapEthUsdPriceAtom,
  updateChainlinkEthUsdPriceOnWalletConnectionEffect,
} from '@/state/atoms/eth-usd-price-atoms'
import { useQuery } from '@apollo/react-hooks'
import BigNumber from 'bignumber.js'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { ETH_PRICE_QUERY } from 'src/services/queries'

export const useEthUsdPrice = () => {
  const { loading, error, data: ethPriceData } = useQuery(ETH_PRICE_QUERY)
  const setUsniswapEthUsdPrice = useSetAtom(uniswapEthUsdPriceAtom)
  const ethUsdPrice = useAtomValue(ethUsdPriceAtom)
  useAtomValue(updateChainlinkEthUsdPriceOnWalletConnectionEffect)

  useEffect(() => {
    if (loading || error != null || ethPriceData == null) setUsniswapEthUsdPrice(null)

    // For some reason, there's a moment where the code goes after the "if" above but it fails to access the "ethPriceData.bundle" property and throws this error:
    // Cannot read properties of undefined (reading 'bundle')
    try {
      setUsniswapEthUsdPrice(new BigNumber(ethPriceData.bundle.ethPrice))
    } catch (error) {
      // console.error('Failed to set eth price', error)
      setUsniswapEthUsdPrice(null)
    }
  }, [loading, error, ethPriceData, setUsniswapEthUsdPrice])

  return {
    ethPriceUsd: ethUsdPrice,
  }
}
