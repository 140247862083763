import BigNumber from 'bignumber.js'
import { useStakingPoolsInfo } from './use-staking-pools-info'

export const useTotalValueLocked = () => {
  const { stakingPoolsInfo } = useStakingPoolsInfo()

  const stakingPoolsUsdValue = stakingPoolsInfo
    .filter((pool) => pool.total.value != null)
    .map((pool) => pool.total.value) as BigNumber[]

  const totalValueLocked =
    stakingPoolsUsdValue.length === stakingPoolsInfo.length
      ? stakingPoolsUsdValue.reduce((acc, curr) => acc.plus(curr))
      : new BigNumber(0)

  return { totalValueLocked }
}
