import classNames from 'classnames/bind'
import { type FC } from 'react'

import { DownArrowImage } from '../../../assets/svg/any/DownArrowImage'
import { WalletLogo } from '../../../assets/svg/Wallet/WalletLogo'
import styles from './WalletButton.module.scss'
import { useShowSelectWalletConnectionPrompt } from '@/hooks/wildcard-settings/useShowComponent/use-show-select=wallet-connection-prompt'
import { BtnSize, BtnType } from '@/ts'
import { BTN_SIZES, BTN_TYPES } from '@/constants'
import { Button } from '../Button'

const cx = classNames.bind(styles)
interface walletButtonProps {
  noLabel?: boolean
  onClickConnectWallet?: () => void
  type?: BtnType
  size?: BtnSize
  full?: boolean
}

export const WalletButton: FC<walletButtonProps> = ({
  noLabel,
  onClickConnectWallet,
  type = BTN_TYPES.SECONDARY,
  size = BTN_SIZES.SMALL_LONGER,
  full = false,
}) => {
  const { showSelectWalletConnectionPrompt } = useShowSelectWalletConnectionPrompt()

  const handleClickConnectWallet = () => {
    if (onClickConnectWallet != null) onClickConnectWallet()
    showSelectWalletConnectionPrompt()
  }

  return (
    <Button
      onClick={handleClickConnectWallet}
      type={type}
      size={size}
      full={full}
      className={cx('wallet-button', { large: size === BTN_SIZES.LARGE })}
    >
      <>
        <WalletLogo className={cx('wallet__logo', { large: size === BTN_SIZES.LARGE })} />
        {!noLabel && 'Connect wallet'}
        <DownArrowImage />
      </>
    </Button>
  )
}
