import { AnimatePresence, motion } from 'framer-motion'
import { useRef } from 'react'
import { AnimatePrompt } from '@/components/prompts/AnimatePrompt'
import { WildcardDiv } from '@/components/wildcard/WildcardDiv'
import { useClickOutside } from '@/hooks/useClickOutside'
import { useWildcardSettings } from '@/hooks/wildcard-settings/use-wildcard-settings'
import classNames from 'classnames/bind'
import styles from './wildcard-handler.module.scss'

const cx = classNames.bind(styles)

export const WildcardHandler = () => {
  const { wildcardSettings, closeWildcard } = useWildcardSettings()

  const wildcardRef = useRef(null)
  useClickOutside(wildcardRef, closeWildcard)

  const exitDuration = wildcardSettings.settings.exitDuration

  return (
    <>
      <AnimatePresence>
        {wildcardSettings.settings.display && wildcardSettings.settings.Component != null && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { type: 'tween', duration: exitDuration ?? 0.3 } }}
            className={cx('cover__all', `cover__all-${wildcardSettings.settings.background}`)}
            key="wildcardCover"
          >
            <div ref={wildcardRef} className={cx('wildcardRef')}>
              <AnimatePrompt animationKey="wildcard" exitDuration={exitDuration}>
                <WildcardDiv Component={wildcardSettings.settings.Component} />
              </AnimatePrompt>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
