import BigNumber from 'bignumber.js'

export { wagmiConfig, WALLETCONNECT_PROJECT_ID, ETH_RPC } from './config'
export { KB_ABI, TOKENS_ABI, UNISWAP_ROUTER_02_ABI, UNISWAP_V2_PAIR_ABI } from './ABI'
export { KUMA_BREEDER_CONTRACT, UNISWAP_ROUTER_O2_CONTRACT, WETH_CONTRACT } from './contracts'
export { BTN_TYPES, BTN_SIZES } from './buttons'

export const ETH_BLOCK_TIME = 12.5

export const BLOCKS_PER_YEAR = new BigNumber((60 / ETH_BLOCK_TIME) * 60 * 24 * 365)

export const SUSHI_PER_BLOCK = 100

export const SUSHI_PER_YEAR = BLOCKS_PER_YEAR.times(SUSHI_PER_BLOCK)

export const TOTAL_ALLOCATION_POINT = 4816

export const ALLOWED_POOLS = [2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13] as const
