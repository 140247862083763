import { useUserAddress } from '@/hooks'
import { useWildcardSettings } from '@/hooks/wildcard-settings/use-wildcard-settings'
import { useShowEnforceEthChainPrompt } from '@/hooks/wildcard-settings/useShowComponent/use-show-enforce-eth-chain-prompt'
import { type FC, useEffect } from 'react'
import useAsyncEffect from 'use-async-effect'
import { useAccount, useChains } from 'wagmi'

interface KumaBreederInitialSetupProps {
  children: JSX.Element
}

export const WalletConnectionHelper: FC<KumaBreederInitialSetupProps> = ({ children }) => {
  const { showEnforceEthChainPrompt } = useShowEnforceEthChainPrompt()
  const { wildcardSettings, closeWildcard } = useWildcardSettings()
  const { address, chainId } = useAccount()
  const chains = useChains()
  useUserAddress()

  const handleAppInitialization = async () => {
    if (address == null) return
  }

  useEffect(() => {
    handleAppInitialization()
  }, [])

  const checkAndHandleChainChange = async (chainId: number) => {
    if (chains.find((chain) => chain.id === chainId) == null) {
      showEnforceEthChainPrompt()
      return
    }

    if (wildcardSettings.settings.componentName === 'EnforceEthChain') {
      closeWildcard(true)
      // await handleRefreshKBreederManager()
    }
  }

  useAsyncEffect(async () => {
    if (chainId != null) {
      await checkAndHandleChainChange(chainId)
    }
  }, [chainId])

  return children
}
