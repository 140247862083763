import BigNumber from 'bignumber.js'
import { useAllStakingOptionsYield } from './use-all-staking-options-yield'

export const useUserTotalYield = () => {
  const { allStakingOptionsYield } = useAllStakingOptionsYield()

  const userTotalYield = Object.values(allStakingOptionsYield).reduce((acc, curr) => {
    acc = acc.plus(curr)
    return acc
  }, new BigNumber(0))

  return { userTotalYield }
}
