export const CrossCloseRound = ({ size, styles, onClick }: { size?: string; styles?: string; onClick?: () => any }) => {
  const finalSize = size ?? '1rem'

  return (
    <svg
      className={styles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 142.93 142.93"
      style={{ width: finalSize, height: finalSize }}
      onClick={onClick}
    >
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <g id="Vordergrund">
            <path
              fill="currentColor"
              d="M35.85,26.47a9.55,9.55,0,0,0-9.38,9.37v.25a11.7,11.7,0,0,0,.54,3,9.7,9.7,0,0,0,2.4,3.72L58,71.47,29.59,99.9a9.84,9.84,0,0,0-2.48,3.63,11.17,11.17,0,0,0-.64,3.18v.39a9.54,9.54,0,0,0,9.37,9.37h.25a9.65,9.65,0,0,0,6.6-2.78q14.4-14.37,28.78-28.77l28.77,28.78a9.68,9.68,0,0,0,6.47,2.76h.39a9.51,9.51,0,0,0,6.83-3.06,9.65,9.65,0,0,0,2.54-6.31v-.4a10.12,10.12,0,0,0-1.32-4.55,17.85,17.85,0,0,0-3-3.47l-27.2-27.2,28.69-28.7a9.59,9.59,0,0,0,2.85-6.54v-.4a9.53,9.53,0,0,0-9.36-9.36h-.4a10.84,10.84,0,0,0-3.28.68,10.39,10.39,0,0,0-3.71,2.61L71.47,58,42.87,29.42A9.76,9.76,0,0,0,39.12,27a11.55,11.55,0,0,0-3-.54ZM0,71.47a71.47,71.47,0,1,1,71.47,71.46A71.47,71.47,0,0,1,0,71.47Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
