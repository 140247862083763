export function makeObservableReducer<T, TR>(target: T) {
  let listeners: Array<(value: TR) => void> = []
  let value = target

  function get() {
    return value
  }

  function set(newValue: T) {
    value = newValue
  }

  function setReducers(action: TR) {
    listeners.forEach((l) => l(action))
  }

  function subscribe(listenerFunc: (action: TR) => void) {
    listeners.push(listenerFunc)
    return () => unsubscribe(listenerFunc) // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc: (value: TR) => void) {
    listeners = listeners.filter((l) => l !== listenerFunc)
  }

  return {
    get,
    set,
    setReducers,
    subscribe,
  }
}
