import { KUMA_BREEDER_CONTRACT, TOKENS_ABI } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import { StakedAmount, StakedAmountAndPid } from '@/ts'
import BigNumber from 'bignumber.js'
import { useReadContracts } from 'wagmi'

export const useAllStakingOptionsStakedAmount = () => {
  const decimalsBigNumber = new BigNumber(10).pow(18)

  const callsList = stakingPools.map((pool) => ({
    abi: TOKENS_ABI,
    address: pool.contract,
    functionName: 'balanceOf',
    args: [KUMA_BREEDER_CONTRACT],
  }))

  const response = useReadContracts({
    contracts: callsList,
  })

  const stakedAmountList = stakingPools.map((pool, index) => {
    const data: StakedAmountAndPid = { pid: pool.PID, stakedAmount: null }
    const stakedAmountBigInt = response.data?.[index].result

    if (stakedAmountBigInt == null) return data

    const stakedAmount = new BigNumber(stakedAmountBigInt.toString()).div(decimalsBigNumber)

    return { pid: pool.PID, stakedAmount }
  })

  const allStakingOptionsStakedAmounts = stakedAmountList.reduce(
    (acc, curr) => {
      acc[curr.pid] = curr.stakedAmount
      return acc
    },
    {} as Record<number, StakedAmount>
  )

  return { allStakingOptionsStakedAmounts }
}
