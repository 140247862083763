import BigNumber from 'bignumber.js'

export const getChainlinkEthUsdPrice = async () => {
  const chainlinkEthPrice = await fetch('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=ETH&tsyms=USD')
  if (!chainlinkEthPrice.ok) {
    return null
  }
  const chainlinkEthPriceJson = await chainlinkEthPrice.json()
  return new BigNumber(chainlinkEthPriceJson.RAW.ETH.USD.PRICE)
}
