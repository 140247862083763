import { SUSHI_PER_YEAR, TOTAL_ALLOCATION_POINT } from '@/constants'
import BigNumber from 'bignumber.js'

export const calculateFarmApr = (allocPoint: BigNumber, dKumaPriceEth: BigNumber, poolLiquidityEth: BigNumber) => {
  if (poolLiquidityEth.isZero()) return null
  const poolWeight = allocPoint.dividedBy(TOTAL_ALLOCATION_POINT)
  const yearlyDkumaRewardAllocation = SUSHI_PER_YEAR.times(poolWeight)
  const apr = yearlyDkumaRewardAllocation.times(dKumaPriceEth).dividedBy(poolLiquidityEth).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr
}
