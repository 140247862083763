import { useEffect } from 'react'
import {
  type SettingsInt,
  type SettingsPayloadInt,
  type WildcardReducerAction,
  type WildcardSettingsAndITBufferPayloadInt,
  type WildcardSettingsInt,
} from '@/ts/WildcardSettings/WildcardSettingsInt'

import { useWildcardReducer } from './use-wildcard-reducer'
import { defaultWildcardSettings } from '@/constants/default-wildcard-settings'
import { makeObservableReducer } from '@/utils/MakeObservableReducer'

const wsStore = makeObservableReducer<WildcardSettingsInt, WildcardReducerAction>(defaultWildcardSettings)

export const useWildcardSettings = () => {
  const [wildcardSettings, dispatch] = useWildcardReducer(wsStore.get())

  useEffect(() => {
    return wsStore.subscribe(dispatch)
  }, [wildcardSettings, dispatch])

  useEffect(() => {
    if (wildcardSettings.settings.display) document.body.style.overflowY = 'hidden'
    if (!wildcardSettings.settings.display) document.body.style.overflowY = 'unset'
  }, [wildcardSettings.settings.display])

  const setWildcardSettings = (settings: SettingsPayloadInt) => {
    wsStore.setReducers({
      type: 'setWildcardSettings',
      payload: settings,
      storeSetter: wsStore.set,
    })
  }

  const setImportTokenBuffer = (settings: SettingsInt[]) => {
    wsStore.setReducers({
      type: 'setImportTokenBuffer',
      payload: settings,
      storeSetter: wsStore.set,
    })
  }

  const addImportTokenBuffer = (settings: SettingsInt[]) => {
    wsStore.setReducers({
      type: 'addImportTokenBuffer',
      payload: settings,
      storeSetter: wsStore.set,
    })
  }

  const setWildcardSettingsAndITBuffer = (settings: WildcardSettingsAndITBufferPayloadInt) => {
    wsStore.setReducers({
      type: 'setWildcardSettingsAndITBuffer',
      payload: settings,
      storeSetter: wsStore.set,
    })
  }

  const forceWildcard = () => {
    wsStore.setReducers({
      type: 'forceWildcard',
      storeSetter: wsStore.set,
    })
  }

  const unforceWildcard = () => {
    wsStore.setReducers({
      type: 'unforceWildcard',
      storeSetter: wsStore.set,
    })
  }

  const closeWildcard = (ignoreForceWildcard = false) => {
    wsStore.setReducers({
      type: 'closeWildcard',
      payload: {
        ignoreForceWildcard,
      },
      storeSetter: wsStore.set,
    })
  }

  const wildcardClickOutside = () => {
    wsStore.setReducers({
      type: 'closeWildcard',
      payload: {
        ignoreForceWildcard: false,
      },
      storeSetter: wsStore.set,
    })
  }

  const setFromImportBuffer = () => {
    wsStore.setReducers({
      type: 'setFromImportBuffer',
      storeSetter: wsStore.set,
    })
  }

  return {
    wildcardSettings,
    setWildcardSettings,
    setImportTokenBuffer,
    addImportTokenBuffer,
    setWildcardSettingsAndITBuffer,
    forceWildcard,
    unforceWildcard,
    closeWildcard,
    wildcardClickOutside,
    setFromImportBuffer,
  }
}
