import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { type FC } from 'react'
import { NavLink } from 'react-router-dom'
import { DownArrowImage } from 'src/assets/svg/any/DownArrowImage'
import { formatAddress } from '@/utils/functions/utils'

import { useHandleNumericInputs } from '../../hooks/useHandleNumericInput'
import { Button } from '../buttons/Button'
import { Loader } from '../loaders/Loader'
import styles from './PoolInfo.module.scss'
import type { AllowedPool } from '@/ts'
import { useSingleStakingPoolInfo } from '@/hooks/pools-info/use-single-staking-pool-info'
import { useStakingPoolsUserInfo } from '@/hooks/pools-info/use-staking-pools-user-info'
import { useAccount } from 'wagmi'
import { useNeedsApprove } from '@/hooks/user/use-needs-approve'
import { useRefetchInfoAfterTransaction } from '@/hooks/user/use-refetch-info-after-transaction'
import { WalletButton } from '../buttons/WalletButton/WalletButton'
import { BTN_SIZES, BTN_TYPES } from '@/constants'
import { useKumaBreeder } from '@/hooks/use-kuma--breeder/use-kuma--breeder'
const cx = classNames.bind(styles)

interface poolMode {
  mode: { staking?: boolean; unstaking?: boolean }
}

interface PoolInfoProps {
  PID: AllowedPool
}

// TODO Store all the fetched values in jotai-query atom
export const PoolInfo: FC<PoolInfoProps> = ({ PID }) => {
  const { address } = useAccount()
  const { refetchInfoAfterTransaction } = useRefetchInfoAfterTransaction(PID)
  const { getNeedsApprove } = useNeedsApprove(PID)
  const { stakingPoolInfo } = useSingleStakingPoolInfo(PID)
  const {
    apr,
    name: poolName,
    image: { 1: image1, 2: image2 },
  } = stakingPoolInfo

  const {
    userInfo: { balance: userBalance, rewards: userRewards, stakedAmount: userStakedAmount },
  } = useStakingPoolsUserInfo(PID)
  const { increaseAllowance, stake, unstake, claim } = useKumaBreeder(PID)

  const zeroBig = new BigNumber(0)
  const [mode, setMode] = useState<poolMode['mode']>({ staking: true })
  const stylesStakeButton = cx('stake__unstake__btn', { 'stake__unstake__btn-active': mode.staking })
  const stylesUnstakeButton = cx('stake__unstake__btn', { 'stake__unstake__btn-active': mode.unstaking })

  const [inputAmountBig, setInputAmountBig] = useState<BigNumber>(zeroBig)
  const inputRef = useRef<HTMLInputElement>(null)
  const [updateCaretPosition, setUpdateCaretPosition] = useState<number>(0)

  const needsApprove = getNeedsApprove(inputAmountBig)

  const [txOngoing, setTxOngoing] = useState({ stake: false, unstake: false, claim: false, approve: false })

  // Handles the change of value of the input
  // Updates the string value, the caret position and the BigNumber amount
  const [caretPosition, amountFormatted, handleNewAmountFormattedInput, setAmountFormatted] = useHandleNumericInputs(
    '',
    0,
    {
      changeBigAmount: setInputAmountBig,
      updateCaretPosition: setUpdateCaretPosition,
    }
  )

  // Updates the caret position when it's told to do so
  useEffect(() => {
    inputRef.current?.setSelectionRange(caretPosition, caretPosition)
  }, [updateCaretPosition])

  useEffect(() => {
    const amountToCheck = mode.staking ? userBalance : userStakedAmount

    if (inputAmountBig.isGreaterThan(amountToCheck)) {
      setInputAmountBig(amountToCheck)
      setAmountFormatted(amountToCheck.toFormat())
    }
  }, [userBalance, userStakedAmount])

  const handleSelectMode = (newMode: 'staking' | 'unstaking') => {
    if (mode[newMode]) return
    setMode({ [newMode]: true })
    refetchInfoAfterTransaction()

    const amountToCheck = newMode === 'staking' ? userBalance : userStakedAmount

    if (inputAmountBig.isGreaterThan(amountToCheck)) {
      setInputAmountBig(amountToCheck)
      setAmountFormatted(amountToCheck.toFormat())
    }
  }

  const handleSetMaxAmount = () => {
    const amountToSet = mode.staking ? userBalance : userStakedAmount
    setAmountFormatted(amountToSet.toFormat())
    setInputAmountBig(amountToSet)
  }

  const handleApprove = async () => {
    setTxOngoing((currValue) => ({ ...currValue, approve: true }))
    increaseAllowance({
      onSettled: () => setTxOngoing((currValue) => ({ ...currValue, approve: false })),
    })
  }

  const handleStake = async () => {
    // if (inputAmountBig.isGreaterThan(userBalance) || inputAmountBig.isZero()) return
    setTxOngoing((currValue) => ({ ...currValue, stake: true }))

    stake(inputAmountBig, {
      onSuccess: () => {
        setAmountFormatted('')
        setInputAmountBig(zeroBig)
      },
      onError: (e) => {
        console.error(e)
      },
      onSettled: () => {
        setTxOngoing((currValue) => ({ ...currValue, stake: false }))
      },
    })
  }

  const handleUnstake = async () => {
    // if (inputAmountBig.isGreaterThan(userStakedAmount) || inputAmountBig.isZero()) return
    setTxOngoing((currValue) => ({ ...currValue, unstake: true }))

    unstake(inputAmountBig, {
      onSuccess: () => {
        setAmountFormatted('')
        setInputAmountBig(zeroBig)
      },
      onError: (e) => {
        console.error(e)
      },
      onSettled: () => {
        setTxOngoing((currValue) => ({ ...currValue, unstake: false }))
      },
    })
  }

  const handleClaim = async () => {
    // if (userRewards.isZero()) return
    setTxOngoing((currValue) => ({ ...currValue, claim: true }))

    claim({
      onError: (e) => {
        console.error('CLAIM ERROR', e)
      },
      onSettled: () => {
        setTxOngoing((currValue) => ({ ...currValue, claim: false }))
      },
    })
  }

  return (
    <div className={cx('staking__box')}>
      <div className={cx('staking__pair__title')}>
        <NavLink className={cx('navLink')} to="/">
          <DownArrowImage style={cx('downArrowImage')} />
        </NavLink>

        <div className={cx('staking__pair__title__imgContainer')}>
          <img src={image1} alt="" />
          {image2 != null && <img className={cx('eth__image')} src={image2} alt="" />}
        </div>

        <h5>{poolName ?? ''}</h5>
      </div>

      <div className={cx('user__staking__info')}>
        <div>
          <h6>Your stake</h6>
          <p>{userStakedAmount.toFormat(3) ?? '0.00'}</p>
        </div>

        <div>
          <h6>Your rewards</h6>
          <p>{userRewards?.toFormat(3) ?? '0.00'} dKUMA</p>
        </div>

        <div>
          <h6>Pool APR</h6>
          <p>{apr?.toFormat(1) ?? '0.0'}%</p>
        </div>
      </div>

      <div className={cx('fees_info')}>
        <p>Pool staking fees: 0%</p>
        <p>Pool unstaking fees: 3%</p>
      </div>

      <div className={cx('stake__unstake__btns')}>
        <h6 className={stylesStakeButton} onClick={() => handleSelectMode('staking')}>
          Stake {poolName ?? ''}
        </h6>

        <h6 className={stylesUnstakeButton} onClick={() => handleSelectMode('unstaking')}>
          UnStake {poolName ?? ''}
        </h6>
      </div>

      <div className={cx('stake__unstake__box')}>
        <h4 className={cx('stake__unstake__box__title')}>
          {mode.staking ? 'Stake' : mode.unstaking ? 'Unstake' : 'Mode Error'} {poolName ?? ''}
        </h4>

        <div className={cx('user__wallet__balance')}>
          <p className={cx('user__wallet')}>{formatAddress(address)}</p>

          <div className={cx('user__balance')}>
            <h6>{mode.staking ? 'Balance: ' : 'Staked: '}</h6>

            <div className={cx('fr')}>
              <h6>{mode.staking ? userBalance.toFormat(3) : userStakedAmount.toFormat(3)}</h6>

              <h6 className={cx('max__button')} onClick={handleSetMaxAmount}>
                Max
              </h6>
            </div>
          </div>
        </div>

        <input
          type="text"
          id="stake-amount-input"
          className={cx('amount__input')}
          placeholder="0.0"
          ref={inputRef}
          value={amountFormatted}
          onBeforeInput={(e) => {
            // @ts-ignore
            if (!parseInt(e.data) && e.data !== '.' && e.data !== ',' && e.data !== '0') e.preventDefault()
          }}
          onChange={(e) => handleNewAmountFormattedInput(e, mode.staking ? userBalance : userStakedAmount)}
          autoComplete="off"
          autoCorrect="off"
        />

        {address == null ? (
          <WalletButton size={BTN_SIZES.LARGE} full={true} />
        ) : mode.staking ? (
          needsApprove.needsApprove ? (
            <Button
              id="stake-btn"
              size={BTN_SIZES.LARGE}
              onClick={handleApprove}
              disabled={address == null}
              full={true}
            >
              <>
                Approve
                {txOngoing.approve && <Loader size="2.5rem" />}
              </>
            </Button>
          ) : (
            <Button id="stake-btn" size={BTN_SIZES.LARGE} onClick={handleStake} disabled={address == null} full={true}>
              <>
                Stake
                {txOngoing.stake && <Loader size="2.5rem" />}
              </>
            </Button>
          )
        ) : mode.unstaking ? (
          <Button id="stake-btn" size={BTN_SIZES.LARGE} onClick={handleUnstake} disabled={address == null} full={true}>
            <>
              Unstake
              {txOngoing.unstake && <Loader size="2.5rem" />}
            </>
          </Button>
        ) : null}
        {address != null ? (
          <Button
            id="claim-btn"
            size={BTN_SIZES.LARGE}
            type={BTN_TYPES.SECONDARY}
            onClick={handleClaim}
            disabled={address == null}
            full={true}
          >
            <>
              Claim
              {txOngoing.claim && <Loader size="2.5rem" />}
            </>
          </Button>
        ) : null}
      </div>
    </div>
  )
}
