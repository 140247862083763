import type { WildcardSettingsInt } from '@/ts/WildcardSettings/WildcardSettingsInt'

export const defaultWildcardSettings: WildcardSettingsInt = {
  settings: {
    display: false,
    componentName: '',
    Component: null,
    background: 'normal',
    forceWildcard: false,
  },
  importTokenBuffer: [],
}
