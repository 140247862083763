import './EnforceEthChain.scss'

import { useRef } from 'react'
import { Warning } from 'src/assets/svg/alerts/Warning'

import { PromptSwapX } from '../prompts/PromptSwapX'
import { useWildcardSettings } from '@/hooks/wildcard-settings/use-wildcard-settings'
import { useDisconnect, useSwitchChain } from 'wagmi'
import { Button } from '../buttons/Button'
import { BTN_SIZES, BTN_TYPES } from '@/constants'

export const EnforceEthChain = () => {
  const { closeWildcard } = useWildcardSettings()
  const { disconnect } = useDisconnect()
  const { chains, switchChain } = useSwitchChain()

  const promptRef = useRef<HTMLInputElement>(null)

  const handleClickDisconnectWallet = async () => {
    disconnect()
    closeWildcard(true)
  }

  const handleClickSwitchToEthChain = () => {
    const chainId = chains[0].id
    switchChain({ chainId })
  }

  return (
    <div className="enforce_eth_chain__prompt__cover">
      <PromptSwapX
        title="Check your network"
        onClickClose={() => {}}
        refference={promptRef}
        hideCross={true}
        body={
          <>
            <div className="enforce_eth_chain__prompt__body">
              <div className="enforce_eth_chain__prompt__body__info">
                <Warning />
                <p>The Kuma Breeder is only available on the Ethereum Blockchain</p>
                {/* <img src={'/img/kuma/rocket.png'} alt={'Kuma rocket'} /> */}
              </div>
              <section className="buttons__container">
                <section className="enforce_eth_chain__prompt__body__button_container switch-network-button">
                  <Button
                    onClick={handleClickSwitchToEthChain}
                    type={BTN_TYPES.PRIMARY}
                    size={BTN_SIZES.MEDIUM}
                    full={true}
                  >
                    <>
                      <div className="enforce_eth_chain__prompt__body__button_container__provider_container">
                        <p>Switch to ETH Blockchain</p>
                      </div>
                    </>
                  </Button>
                </section>

                <section className="enforce_eth_chain__prompt__body__button_container">
                  <Button
                    onClick={handleClickDisconnectWallet}
                    type={BTN_TYPES.SECONDARY}
                    size={BTN_SIZES.MEDIUM}
                    full={true}
                  >
                    <>
                      <div className="enforce_eth_chain__prompt__body__button_container__provider_container">
                        <p>Disconnect wallet</p>
                      </div>
                    </>
                  </Button>
                </section>
              </section>
            </div>
          </>
        }
      />
    </div>
  )
}
