import { type FunctionComponent } from 'react'

interface WalletLogoProps {
  className?: string
}

export const WalletLogo: FunctionComponent<WalletLogoProps> = ({ className }) => {
  return (
    <svg className={`wallet__logo ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 365.43 333.85">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path
            className="cls-1"
            fill="currentColor"
            d="M267.45,0H269c5.94.17,12,1.83,16.62,5.75s7.31,9.49,8.48,15.27c1.92,9.14,1.59,18.54,1.72,27.82,0,6.82,0,32.64,0,39.46H278.38c0-9.11,0-37.22,0-46.34-.1-6.09-.06-12.26-1.59-18.2A9.16,9.16,0,0,0,275.21,20a7.72,7.72,0,0,0-5.64-2.46c-8.11-.24-16,1.85-23.85,3.74Q161.07,44.42,76.47,67.81C72.18,69,67.79,70,63.68,71.75c-6,2.54-12.19,4.81-17.89,8C38.4,83.38,31.4,88.08,26.11,94.42a49.89,49.89,0,0,1,28.34-7.68H312.7c18.89.06,37,11.32,46.22,27.68a45,45,0,0,1,5.93,16.45c.75,4.53.55,9.13.58,13.71,0,59.13,0,78.25,0,137.39A55.2,55.2,0,0,1,362,302.67a49,49,0,0,1-24.16,26.17c-6.76,3.46-14.4,4.73-21.93,5H49.34c-8-.32-16.08-1.79-23.1-5.75A48.76,48.76,0,0,1,2.31,299.45C.07,292.46,0,285,0,277.78c0-50.11,0-60.21,0-110.31,0-17.18-.2-34.38,1-51.54.82-7.55,2.05-15.21,5.33-22.14C11.22,83,20.22,74.53,30.26,68.48c2.79-1.73,5.76-3.12,8.63-4.7a163.76,163.76,0,0,1,17.65-7.86,56.24,56.24,0,0,1,7.76-2.73q71.73-20,143.57-39.62c15.49-4.17,30.82-9,46.53-12.22A100.21,100.21,0,0,1,267.45,0ZM48.15,104.43a31.93,31.93,0,0,0-16.13,7,41.67,41.67,0,0,0-13.88,21.88c-1,6.76-.3,13.61-.8,20.4-.05,53.91,0,67.81-.05,121.72A101.85,101.85,0,0,0,17.87,290a31.32,31.32,0,0,0,25.89,25.88c4,.67,8.15.51,12.23.56H309.25c4.22,0,8.49.21,12.66-.61A31.34,31.34,0,0,0,337,308.59a32.09,32.09,0,0,0,10.83-22.82c.12-3.3,0-6.6.06-9.91v-132c0-4,.25-8.09-.73-12-2.9-12.32-13.05-22.25-24.95-26.08-3.89-1.43-8.08-1.6-12.18-1.61H56A67.75,67.75,0,0,0,48.15,104.43Z"
          />
          <circle className="cls-1" fill="currentColor" cx="272.35" cy="216.54" r="23.71" />
        </g>
      </g>
    </svg>
  )
}
