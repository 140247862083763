import classNames from 'classnames/bind'

import { StakingOptions } from '../../components/StakingOptions/StakingOptions'
import { TotalValueLocked } from '../../components/TotalValueLocked/TotalValueLocked'
import { YieldInfo } from '../../components/YieldInfo/YieldInfo'
import styles from './StakingOptionsPage.module.scss'

const cx = classNames.bind(styles)

// TODO Store all the fetched values in jotai-query atom
export const StakingOptionsPage = () => {
  return (
    <div className={cx('staking__options__page')}>
      <div className={cx('staking__options__wrapper')}>
        <div className={cx('tvl__yield__container')}>
          <TotalValueLocked />
          <YieldInfo />
        </div>
        <StakingOptions />
      </div>
    </div>
  )
}
