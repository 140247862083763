import type React from 'react'
import { useEffect } from 'react'

interface ClickOutsideState {
  htmlElement: React.RefObject<HTMLElement>
  function: () => void
}

export const useClickOutside = (
  ref: ClickOutsideState['htmlElement'],
  onClickOutside: ClickOutsideState['function'],
) => {
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      // @ts-ignore
      if (ref.current != null && !ref.current.contains(e.target) && e.button === 0) {
        onClickOutside()
      }
    }
    // Bind the element listener
    document.addEventListener('mousedown', handleClickOutside)

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}
