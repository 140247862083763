import classNames from 'classnames/bind'

import styles from './YieldInfo.module.scss'
import { useUserTotalYield } from '@/hooks/pools-info/use-user-total-yield'

const cx = classNames.bind(styles)

export const YieldInfo = () => {
  const { userTotalYield } = useUserTotalYield()
  const stylesMainContainer = cx('main__container')

  return (
    <div className={stylesMainContainer}>
      <h6>YIELD $dKUMA</h6>
      <h5>{userTotalYield != null ? userTotalYield.toFormat(3) : '0.000'}</h5>
    </div>
  )
}
