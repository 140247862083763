import type { AllowedPool } from '@/ts'
import { useStakingPoolsInfo } from './use-staking-pools-info'

export const useSingleStakingPoolInfo = (PID: AllowedPool) => {
  const { stakingPoolsInfo } = useStakingPoolsInfo()

  const stakingPoolInfo = stakingPoolsInfo.find((pool) => pool.PID === PID)!

  return { stakingPoolInfo }
}
