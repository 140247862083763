import { BigNumber } from 'bignumber.js'
import { useRef, useState } from 'react'
const amountRegex = /^([0-9]+\.?[0-9]*|[0-9]*\.?[0-9]+)?$/
const hasDot = new RegExp('.')
// const amountRegex = new RegExp('^-?\\d+\\.?\\d*$')

export const useHandleNumericInputs = (
  initialValue: string,
  initialCaretPosition: number,
  optionalFunctions?: {
    changeAmount?: (value: number) => void
    changeBigAmount?: (value: BigNumber) => void
    updateCaretPosition?: (value: number | ((currValue: number) => number)) => void
  },
  optionalParameters?: {
    minAmount?: BigNumber
    maxAmount?: BigNumber
    // * If this parameter is set to true valueBig will be set to the previous value if the input is an empty string
    // * Example usecase the slippage inputs. If it's an empty string you want to keep the old value
    // ? If it's empty string it will be treated as a zero so the min value is set. If the user wants another value he can input it
    // oldNumericValueIfValueEmpty?: boolean
    // * If enabled allows to set the amount string below the min amount.
    // * The numeric amount won't go lower than the min amount
    allowUnderMinAmount?: boolean
    maxDecimals?: number
  },
): [
  caretPositionRef: number,
  numberString: string,
  handleBigNumberInput: (e: React.FormEvent<HTMLInputElement>, newMaxAmount?: BigNumber) => void,
  setNumberString: (value: string) => void,
] => {
  const [numberString, setNumberString] = useState<string>(initialValue)
  const [caretPosition, setCaretPosition] = useState<number>(initialCaretPosition)
  const caretPositionRef = useRef<number>(initialCaretPosition)
  caretPositionRef.current = caretPosition

  const handleBigNumberInput = (e: React.FormEvent<HTMLInputElement>, newMaxAmount?: BigNumber) => {
    const minAmount = optionalParameters?.minAmount
    const maxAmount = newMaxAmount ?? optionalParameters?.maxAmount

    const prevValue = numberString
    // @ts-ignore
    let newCaretPosition = e.target.selectionStart
    // @ts-ignore
    let nativeValue: string = e.target.value ?? ''

    // * Allows the user to use the comma as a dot
    if (
      // * If you remove the number after the comma you will get nativeValue.charAt(newCaretPosition - 1) === ','
      // * The first check avoids a bug when a number after a comma gets deleted (the bug makes nothing gets removed)
      // * The first check then makes it impossible to turn a comma into a dot if it's added right before an existing comma
      // * The second check makes it possible. If after the comma there's another comma this means the user wants to use the comma as a dot
      (nativeValue.charAt(newCaretPosition - 1) === ',' && prevValue.charAt(newCaretPosition - 1) !== ',') ||
      (nativeValue.charAt(newCaretPosition - 1) === ',' && nativeValue.charAt(newCaretPosition) === ',')
    ) {
      nativeValue = nativeValue.substring(0, newCaretPosition - 1) + '.' + nativeValue.substring(newCaretPosition)
    }

    // * If the symbol added is a dot and the previous value didn't have a dot
    if (nativeValue.charAt(newCaretPosition - 1) === '.' && hasDot.test(prevValue)) {
      // * If the next symbol is a comma add 1 to the new caret position
      if (nativeValue.charAt(newCaretPosition) === ',') newCaretPosition++
    }

    let value = nativeValue.replaceAll(',', '')

    if (nativeValue.charAt(nativeValue.length - 1) === ',') value += '.'

    // * If the valuse is just a dot some value has to be added on the right side
    if (value === '.') {
      // * If there's a minAmount the value on the right will have to be it
      if (minAmount != null) {
        // * If the min amount has decimals the value will be replaced by the minAmount
        if (minAmount.toFixed().split('.')[1]) {
          value = minAmount.toFixed()
          newCaretPosition = value.length
          // * If the minAmount hasn't got decimals the minAmount is added to the right
        } else {
          value = minAmount.toFixed() + '.'
          newCaretPosition = value.length
        }
        // * If there isn't a minAmount the value will be a zero
      } else {
        value = '0.'
        newCaretPosition = 2
      }
    }

    if (!amountRegex.test(value)) {
      // * The caret position is after the invalid character, so the caret position must be reduced by 1
      setCaretPosition(newCaretPosition - 1)
      setNumberString(prevValue)
      if (optionalFunctions?.updateCaretPosition != null)
        optionalFunctions.updateCaretPosition((currValue) => currValue + 1)
      return
    }

    let valueBig = new BigNumber(value === '' ? 0 : value)
    if (minAmount != null)
      if (valueBig.isLessThan(minAmount)) {
        valueBig = minAmount
        if (optionalParameters?.allowUnderMinAmount !== true && value !== '') value = minAmount.toFixed()
      }
    if (maxAmount != null) {
      if (valueBig.isGreaterThan(maxAmount) && value !== '') {
        valueBig = maxAmount
        value = maxAmount.toFixed()
      }
    }

    const valueFormatted = getFormattedValue(value, optionalParameters?.maxDecimals)

    // * If both values are equal it means the user tried to delete a comma. In this case the caret position must increase in 1 so it goes back to after the comma
    if (valueFormatted === prevValue) newCaretPosition++
    if (valueFormatted !== prevValue)
      newCaretPosition = getNewCaretPosition(valueFormatted, prevValue, newCaretPosition)
    setCaretPosition(newCaretPosition)
    setNumberString(valueFormatted)
    if (optionalFunctions?.updateCaretPosition != null)
      optionalFunctions.updateCaretPosition((currValue) => currValue + 1)

    // * If a dot has been added at the end the number amount doesn't change
    const valueFormattedNoEndDot =
      valueFormatted.charAt(valueFormatted.length - 1) === '.'
        ? valueFormatted.substring(0, valueFormatted.length - 1)
        : valueFormatted
    const prevValueNoEndDot =
      prevValue.charAt(prevValue.length - 1) === '.' ? prevValue.substring(0, prevValue.length - 1) : prevValue
    if (valueFormattedNoEndDot !== prevValueNoEndDot) {
      if (optionalFunctions?.changeAmount != null) optionalFunctions.changeAmount(valueBig.toNumber())
      if (optionalFunctions?.changeBigAmount != null) optionalFunctions.changeBigAmount(valueBig)
    }
  }

  return [caretPositionRef.current, numberString, handleBigNumberInput, setNumberString]
}

const getFormattedValue = (value: string, decimals?: number): string => {
  if (value.includes('.')) {
    const decimalsStartIndex = value.indexOf('.') + 1
    value = value.slice(0, decimalsStartIndex + (decimals ?? 18))
  }

  const [integerPart, decimalPart] = value.split('.')
  if (integerPart.length > 0) {
    value = new BigNumber(integerPart).toFormat() + (value.includes('.') ? '.' : '') + (decimalPart ?? '')
  }

  return value
}

const getNewCaretPosition = (value: string, prevValue: string, caretPosition: number): number => {
  const isSymbolAdded = prevValue.length < value.length
  const commasAmountBeforeCaretPosition =
    prevValue.substring(0, caretPosition - (isSymbolAdded ? 1 : 0)).split(',').length - 1
  const newCommasAmountBeforeCaretPosition = value.substring(0, caretPosition).split(',').length - 1

  let newCaretPosition = caretPosition + (newCommasAmountBeforeCaretPosition - commasAmountBeforeCaretPosition)
  if (newCaretPosition && value.length && value[newCaretPosition - 1] === ',') {
    newCaretPosition--
  }

  return newCaretPosition
}
