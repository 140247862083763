import { UNISWAP_V2_PAIR_ABI } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import { useAllSingleTokensPrice } from './use-all-single-tokens-price'
import { useReadContracts } from 'wagmi'
import { chunkArray } from '@/utils'
import BigNumber from 'bignumber.js'
import { StakingOptionPrice, StakingOptionPriceWithPid } from '@/ts'
import { useEthUsdPrice } from '@/hooks/use-eth-usd-price'

export const useAllLpTokensPrice = () => {
  const { allSingleTokensPrice } = useAllSingleTokensPrice()
  const { ethPriceUsd } = useEthUsdPrice()
  const decimalsBigNumber = new BigNumber(10).pow(18)

  const stakingPools_OnlyLP = stakingPools.filter((pool) => pool.lp?.isLpToken)

  const reservesCallsList = stakingPools_OnlyLP.map((pool) => ({
    abi: UNISWAP_V2_PAIR_ABI,
    address: pool.contract,
    functionName: 'getReserves',
  }))

  const lpTotalSupplyCallsList = stakingPools_OnlyLP.map((pool) => ({
    abi: UNISWAP_V2_PAIR_ABI,
    address: pool.contract,
    functionName: 'totalSupply',
  }))

  const callsList = [...reservesCallsList, ...lpTotalSupplyCallsList]

  const result = useReadContracts({
    contracts: callsList,
  })

  const chunkedResult = chunkArray(result.data ?? [], stakingPools_OnlyLP.length)

  const reservesList = chunkedResult[0] as
    | {
        error?: undefined
        result: [bigint, bigint, number]
        status: 'success'
      }[]
    | {
        error: Error
        result?: undefined
        status: 'failure'
      }[]

  const totalSupplyList = chunkedResult[1] as
    | {
        error?: undefined
        result: bigint
        status: 'success'
      }[]
    | {
        error: Error
        result?: undefined
        status: 'failure'
      }[]

  // console.log('reserves', reservesList)
  // console.log('totalSupply', totalSupplyList)

  const allLpTokensReserves = stakingPools_OnlyLP.reduce(
    (acc, pool, index) => {
      const reserve0 = reservesList?.[index]?.result?.[0]
      const reserve1 = reservesList?.[index]?.result?.[1]

      const reserve0BigNumber =
        reserve0 != null ? new BigNumber(reserve0.toString()).dividedBy(decimalsBigNumber) : null
      const reserve1BigNumber =
        reserve1 != null ? new BigNumber(reserve1.toString()).dividedBy(decimalsBigNumber) : null

      return {
        ...acc,
        [pool.PID]: {
          pid: pool.PID,
          reserve0: reserve0BigNumber, // token
          reserve1: reserve1BigNumber, // ETH
        },
      }
    },
    {} as Record<number, { pid: number; reserve0: BigNumber | null; reserve1: BigNumber | null }>
  )

  const allLpTokensTotalSupply = stakingPools_OnlyLP.reduce(
    (acc, pool, index) => {
      const totalSupply = totalSupplyList?.[index]?.result
      const totalSupplyBigNumber =
        totalSupply != null ? new BigNumber(totalSupply.toString()).dividedBy(decimalsBigNumber) : null
      return {
        ...acc,
        [pool.PID]: {
          pid: pool.PID,
          totalSupply: totalSupplyBigNumber,
        },
      }
    },
    {} as Record<number, { pid: number; totalSupply: BigNumber | null }>
  )

  const allLpTokensPriceList: StakingOptionPriceWithPid[] = stakingPools_OnlyLP.map((pool) => {
    const missingPrice: StakingOptionPriceWithPid = { pid: pool.PID, ethPrice: null, usdPrice: null }

    const lpPReserves = allLpTokensReserves[pool.PID]
    const lpTotalSupply = allLpTokensTotalSupply[pool.PID]

    if (lpPReserves.reserve0 == null || lpPReserves.reserve1 == null || lpTotalSupply.totalSupply == null) {
      return missingPrice
    }

    const token0Price = allSingleTokensPrice[pool.lp!.token0Pid]

    // You can still get the pool's ETH value and calculate the APR if the usdPrice is null
    if (token0Price == null || token0Price.ethPrice == null) {
      return missingPrice
    }

    const token0EthValue = lpPReserves.reserve0.multipliedBy(token0Price.ethPrice)
    const token0UsdValue = token0Price.usdPrice != null ? lpPReserves.reserve0.multipliedBy(token0Price.usdPrice) : null
    const token1UsdValue = ethPriceUsd != null ? lpPReserves.reserve1.multipliedBy(ethPriceUsd) : null

    const lpEthValue = token0EthValue.plus(lpPReserves.reserve1)
    const lpUsdValue = token0UsdValue != null && token1UsdValue != null ? token0UsdValue.plus(token1UsdValue) : null

    const singleTokenEthPrice = lpEthValue.dividedBy(lpTotalSupply.totalSupply)
    const singleTokenUsdPrice = lpUsdValue != null ? lpUsdValue.dividedBy(lpTotalSupply.totalSupply) : null

    return {
      pid: pool.PID,
      ethPrice: singleTokenEthPrice,
      usdPrice: singleTokenUsdPrice,
    }
  })

  const allLpTokensPrice = allLpTokensPriceList.reduce(
    (acc, curr) => {
      acc[curr.pid] = { ethPrice: curr.ethPrice, usdPrice: curr.usdPrice }
      return acc
    },
    {} as Record<number, StakingOptionPrice>
  )

  return { allLpTokensPrice }
}
