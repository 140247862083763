import BigNumber from 'bignumber.js'

/**
 *
 * @param uint256 First array value is the decimals, the second is the value without decimals
 */
export const uint256ToBn = (uint256: readonly [string | bigint, string | bigint]): BigNumber => {
  const [decimals, value] = uint256
  return new BigNumber(value.toString()).div(BigNumber(decimals.toString()))
}
