import './index.scss'

// import registerServiceWorker from './registerServiceWorker'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { apolloClient } from './services/apolloClient'
import { ApolloProvider } from '@apollo/react-hooks'
import { KumaBreederProvider } from './state/provider'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <KumaBreederProvider>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </KumaBreederProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// registerServiceWorker()
