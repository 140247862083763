export const BurgerIcon = ({ size, styles }: { size?: string; styles: string }) => {
  const finalSize = size ?? '1rem'

  return (
    <svg
      className={styles}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: finalSize, height: finalSize }}
    >
      <path
        d="M19.1 17.4005H2.9C2.40321 17.4005 2 17.7948 2 18.2807C2 18.7665 2.40321 19.1608 2.9 19.1608H19.1C19.5968 19.1608 20 18.7665 20 18.2806C20 17.7948 19.5968 17.4005 19.1 17.4005ZM2.9 5.07847H19.1C19.5968 5.07847 20 4.68419 20 4.19832C20 3.71246 19.5968 3.31818 19.1 3.31818H2.9C2.40321 3.31821 2 3.71249 2 4.19835C2 4.68419 2.40321 5.07847 2.9 5.07847ZM19.1 10.3594H2.9C2.40321 10.3594 2 10.7537 2 11.2395C2 11.7254 2.40321 12.1197 2.9 12.1197H19.1C19.5968 12.1197 20 11.7254 20 11.2395C20 10.7537 19.5968 10.3594 19.1 10.3594Z"
        fill="currentColor"
      />
    </svg>
  )
}
