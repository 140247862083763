import { KB_ABI, KUMA_BREEDER_CONTRACT, TOKENS_ABI } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import type { AllowedPool, StakingPoolUserInfo } from '@/ts'
import BigNumber from 'bignumber.js'
import { useAccount, useReadContracts } from 'wagmi'
import { useAllStakingOptionsYield } from './use-all-staking-options-yield'

export const useStakingPoolsUserInfo = (PID: AllowedPool) => {
  const decimalsBigNumber = new BigNumber(10).pow(18)
  const { allStakingOptionsYield, refetchAllStakingOptionsYield } = useAllStakingOptionsYield()
  const { address } = useAccount()
  const stakingPool = stakingPools.find((pool) => pool.PID === PID)!

  const response = useReadContracts({
    contracts: [
      {
        abi: KB_ABI,
        address: KUMA_BREEDER_CONTRACT,
        functionName: 'userInfo',
        args: [BigInt(PID), address!],
      },

      {
        abi: TOKENS_ABI,
        address: stakingPool.contract,
        functionName: 'balanceOf',
        args: [address!],
      },
    ],
  })

  const [userInfo, userBalance] = response.data ?? [null, null]

  const stakedAmountString = userInfo?.result != null ? userInfo.result[0].toString() : '0'
  const stakedAmountBigNumber = new BigNumber(stakedAmountString).div(decimalsBigNumber)

  const balanceString = userBalance?.result != null ? userBalance.result.toString() : '0'
  const balanceBigNumber = new BigNumber(balanceString).div(decimalsBigNumber)

  const userPoolInfo: StakingPoolUserInfo = {
    balance: balanceBigNumber,
    stakedAmount: stakedAmountBigNumber,
    rewards: allStakingOptionsYield[PID],
  }

  const refetchUserInfo = () => {
    response.refetch()
    refetchAllStakingOptionsYield()
  }

  return { userInfo: userPoolInfo, refetchUserInfo }
}
