import { mainnet } from 'wagmi/chains'
import { createConfig, http } from 'wagmi'
import { walletConnect } from 'wagmi/connectors'
import { WALLETCONNECT_PROJECT_ID } from './walletconnect-project-id'
import { ETH_RPC } from './rpc'

export const wagmiConfig = createConfig({
  chains: [mainnet],
  connectors: [
    walletConnect({
      projectId: WALLETCONNECT_PROJECT_ID,
      metadata: {
        name: 'Kuma Breeder',
        description: 'Stake meme tokens, earn dKUMA rewards!',
        url: 'breeder.kumatokens.com',
        icons: ['https://breeder.kumatokens.com/KumaBreeder_Logo.jpg'],
      },
      qrModalOptions: { themeMode: 'dark' },
    }),
    // metaMask({ extensionOnly: true }),
  ],
  transports: {
    [mainnet.id]: http(ETH_RPC),
  },
})
