import { useAllLpTokensPrice } from './use-all-lp-tokens-price'
import { useAllSingleTokensPrice } from './use-all-single-tokens-price'

export const useAllStakingOptionsPrice = () => {
  const { allSingleTokensPrice } = useAllSingleTokensPrice()
  const { allLpTokensPrice } = useAllLpTokensPrice()

  const allStakingOptionsPrice = {
    ...allSingleTokensPrice,
    ...allLpTokensPrice,
  }

  return {
    allStakingOptionsPrice,
  }
}
