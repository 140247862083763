import BigNumber from 'bignumber.js'
import { useAllStakingPoolsAllowance, useSingleStakingOptionAllowance } from '@/hooks'
import { AllowedPool } from '@/ts'

type NeedsApprove =
  | {
      needsApprove: false
      approveAmount?: BigNumber | null
    }
  | {
      needsApprove: true
      approveAmount: BigNumber
    }

export const useNeedsApprove = (pid: AllowedPool) => {
  const { allowance } = useSingleStakingOptionAllowance(pid)

  const getNeedsApprove = (amountToStake: BigNumber): NeedsApprove => {
    if (allowance == null) return { needsApprove: false }

    const needsApprove = amountToStake.isGreaterThan(allowance)
    const approveAmount = needsApprove ? amountToStake.minus(allowance) : new BigNumber(0)

    return { needsApprove, approveAmount }
  }

  return { getNeedsApprove }
}
