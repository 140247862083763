// import BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'

import styles from './StakingOptions.module.scss'
import { StakingOptionsButtonColumn } from './StakingOptionsButtonColumn/StakingOptionsButtonColumn'
import { StakingOptionsButtonTableRow } from './StakingOptionsButtonTableRow/StakingOptionsButtonTableRow'
import { useStakingPoolsInfo } from '@/hooks/pools-info/use-staking-pools-info'
const cx = classNames.bind(styles)

export const StakingOptions = () => {
  const { stakingPoolsInfo } = useStakingPoolsInfo()
  const stylesTableCell = cx('table__cell')

  const activeStakingPools = stakingPoolsInfo.filter((pool) => pool.unactive !== true)

  return (
    <div className={cx('staking__options__container')}>
      <div className={cx('table__main__wrapper')}>
        <div className={cx('table__head')}>
          <div className={cx('table__row')}>
            <h4 className={stylesTableCell}>Pair</h4>
            <h4 className={stylesTableCell}>Token</h4>
            <h4 className={stylesTableCell}>APR</h4>
            <h4 className={stylesTableCell}>Total</h4>
          </div>
        </div>
        <div className={cx('table__body')}>
          {activeStakingPools.map((pool) => (
            <StakingOptionsButtonTableRow
              key={pool.PID}
              PID={pool.PID}
              image1={pool.image[1]}
              image2={pool.image[2]}
              tokenName={pool.name}
              apr={pool.apr ?? undefined}
              totalStaked={pool.total?.staked ?? undefined}
              totalStakedValue={pool.total?.value ?? undefined}
              containerStyles={cx('table__row', 'table__row-clickable')}
              cellStyles={cx('table__cell', 'table__cell__body')}
            />
          ))}
        </div>
      </div>
      <div className={cx('columnButtons__container')}>
        {activeStakingPools.map((pool) => (
          <StakingOptionsButtonColumn
            key={pool.PID}
            PID={pool.PID}
            image1={pool.image[1]}
            image2={pool.image[2]}
            tokenName={pool.name}
            apr={pool.apr ?? undefined}
            totalStaked={pool.total?.staked ?? undefined}
            totalStakedValue={pool.total?.value ?? undefined}
          />
        ))}
      </div>
    </div>
  )
}
