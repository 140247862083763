import classNames from 'classnames/bind'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { BurgerIcon } from 'src/assets/svg/any/BurgerIcon'

import { CrossCloseRound } from '../../assets/svg/any/CrossClose'
import { DownArrowImage } from '../../assets/svg/any/DownArrowImage'
import { KumaBreederLogo } from '../../assets/svg/KumaBreederLogo/KumaBreederLogo'
import { WalletLogo } from '../../assets/svg/Wallet/WalletLogo'
import { formatAddress } from '../../utils/functions/utils'
import { useClickOutside } from '../../hooks/useClickOutside'
import { WalletButton } from '../buttons/WalletButton/WalletButton'
import styles from './Header.module.scss'
import { useShowAccountDetailsPrompt } from '@/hooks/wildcard-settings/useShowComponent/use-show-account-details-prompt'
import { useAccount } from 'wagmi'

const cx = classNames.bind(styles)

export const Header = () => {
  const stylesAddress = cx('address__container__address')
  const stylesAddressWalletLogo = cx('address__container__walletLogo')
  const account = useAccount()
  const { showAccountDetailsPrompt } = useShowAccountDetailsPrompt()

  const [displayBurguerMenu, setDisplayBurguerMenu] = useState<boolean>(false)
  const burgerMenuRef = useRef(null)
  useClickOutside(burgerMenuRef, () => setDisplayBurguerMenu(false))

  useEffect(() => {
    if (displayBurguerMenu) document.body.style.overflowY = 'hidden'
    if (!displayBurguerMenu) document.body.style.overflowY = 'unset'
  }, [displayBurguerMenu])

  return (
    <nav className={cx('navbar')}>
      <div className={cx('navbar_background')}>
        <NavLink to="/" className={cx('noUserSelect')}>
          <KumaBreederLogo className={cx('kumaBreeder__logo')} />
        </NavLink>

        <div className={cx('options__container')}>
          <ul>
            <NavLink to="/">Staking Pools</NavLink>
            <a
              className={cx('link__website')}
              href="https://www.kumatokens.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Website
            </a>
            <a
              className={cx('link__swapx')}
              href="https://swap.kumatokens.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Kuma SwapX
            </a>
            <a href="https://dbreeder.kumatokens.com" target="_blank" rel="noreferrer noopener">
              dKuma Breeder
            </a>
          </ul>

          <div className={cx('mla')}>
            {account.address != null ? (
              <div className={cx('address__container')} onClick={showAccountDetailsPrompt}>
                <p className={stylesAddress}>{formatAddress(account.address)}</p>
                <span className={stylesAddressWalletLogo}>
                  <WalletLogo />
                </span>
                <DownArrowImage />
              </div>
            ) : (
              <>
                <WalletButton />
              </>
            )}
          </div>
        </div>

        <div className={cx('mla')} onClick={() => setDisplayBurguerMenu(true)}>
          <BurgerIcon styles={cx('burguerMenu__button')} size={'3rem'} />
        </div>
      </div>

      <AnimatePresence>
        {displayBurguerMenu && (
          <div className={cx('cover__all', { active: displayBurguerMenu })}>
            <motion.div
              className={cx('options__container__burguerMenu', { active: displayBurguerMenu })}
              ref={burgerMenuRef}
              initial={{ x: 300 }}
              animate={{ x: 0 }}
              exit={{ x: 300 }}
            >
              <CrossCloseRound
                size="2.2rem"
                styles={cx('options__container__burguerMenu__close')}
                onClick={() => setDisplayBurguerMenu(false)}
              />
              <ul>
                <NavLink to="/" onClick={() => setDisplayBurguerMenu(false)}>
                  Staking Pools
                </NavLink>
                <a
                  className={cx('link__website')}
                  href="https:/www.kumatokens.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Website
                </a>
                <a
                  className={cx('link__swapx')}
                  href="https://swap.kumatokens.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Kuma SwapX
                </a>
                <a href="https://dbreeder.kumatokens.com" target="_blank" rel="noreferrer noopener">
                  dKuma Breeder
                </a>
              </ul>
              <div>
                {account.address != null ? (
                  <div
                    className={cx('address__container')}
                    onClick={() => {
                      setDisplayBurguerMenu(false)
                      showAccountDetailsPrompt()
                    }}
                  >
                    <p className={stylesAddress}>{formatAddress(account.address)}</p>
                    <span className={stylesAddressWalletLogo}>
                      <WalletLogo />
                    </span>
                    <DownArrowImage />
                  </div>
                ) : (
                  <>
                    <WalletButton
                      onClickConnectWallet={() => {
                        setDisplayBurguerMenu(false)
                      }}
                    />
                  </>
                )}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </nav>
  )
}
