import type BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { type FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './StakingOptionsButtonColumn.module.scss'
const cx = classNames.bind(styles)

interface StakingOptionsButtonColumnProps {
  PID: number
  image1: string
  image2?: string
  tokenName: string
  apr?: BigNumber
  totalStaked?: BigNumber
  totalStakedValue?: BigNumber
}

export const StakingOptionsButtonColumn: FC<StakingOptionsButtonColumnProps> = ({
  PID,
  image1,
  image2,
  tokenName,
  apr,
  totalStaked,
  totalStakedValue,
}) => {
  const stylesButtonContainer = cx('button__container')
  const stylesImagesWrapper = cx('images__wrapper', 'df_fr')
  const stylesTokenNameWrapper = cx('tokenName__wrapper', 'df_fr')
  const stylesApyWrapper = cx('apy__wrapper', 'df_fr')
  const stylesDisplayFlex = cx('df_fr')
  const stylesTotalWrapper = cx('total__wrapper')
  const stylesMarginLeftAuto = cx('mla')

  return (
    <Link to={`/pool/${PID}`} className={stylesButtonContainer}>
      <div className={stylesImagesWrapper}>
        <h4>Pair</h4>
        <div className={cx('images__wrapper__container')}>
          <img src={image1} alt="image1" />
          {image2 && <img className={cx('eth__image')} src={image2} alt="image2" />}
        </div>
      </div>

      <div className={stylesTokenNameWrapper}>
        <h4>Token</h4>
        <p>{tokenName}</p>
      </div>

      <div className={stylesApyWrapper}>
        <h4>APR</h4>
        <p>{apr ? apr.toFormat(1) : '--'}%</p>
      </div>

      <div className={stylesDisplayFlex}>
        <h4>Total</h4>
        <div className={stylesTotalWrapper}>
          <p>{totalStaked ? totalStaked.toFormat(3) : '0.00'}</p>
          <p className={stylesMarginLeftAuto}>${totalStakedValue ? totalStakedValue.toFormat(2) : '0.00'}</p>
        </div>
      </div>
    </Link>
  )
}
