import classNames from 'classnames/bind'

import styles from './TotalValueLocked.module.scss'
import { useTotalValueLocked } from '@/hooks/pools-info/use-total-value-locked'

const cx = classNames.bind(styles)

export const TotalValueLocked = () => {
  const { totalValueLocked } = useTotalValueLocked()

  return (
    <div className={cx('main__container')}>
      <h6>Total Value Locked:</h6>
      <h5>{totalValueLocked.toFormat(2)} USD</h5>
    </div>
  )
}
