import classNames from 'classnames/bind'
import { useParams, Navigate } from 'react-router-dom'
import { ALLOWED_POOLS } from 'src/constants'

import { PoolInfo } from '../../components/PoolInfo/PoolInfo'
import styles from './PoolInfoPage.module.scss'
import type { AllowedPool } from '@/ts'
const cx = classNames.bind(styles)

export const PoolInfoPage = () => {
  const { PID } = useParams()

  if (PID == null || !ALLOWED_POOLS.includes(parseInt(PID) as AllowedPool)) {
    return <Navigate to={'/'} />
  }

  const pidNumber = parseInt(PID) as AllowedPool

  const stylesMainWrapper = cx('main__wrapper')
  const stylesPoolInfoWrapper = cx('pool__info__wrapper')

  return (
    <div className={stylesMainWrapper}>
      <div className={stylesPoolInfoWrapper}>
        <PoolInfo PID={pidNumber} />
      </div>
    </div>
  )
}
