import { KUMA_BREEDER_CONTRACT, TOKENS_ABI } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import { AllowedPool } from '@/ts'
import BigNumber from 'bignumber.js'
import { useAccount, useReadContract } from 'wagmi'

export const useSingleStakingOptionAllowance = (pid: AllowedPool) => {
  const decimalsBigNumber = new BigNumber(10).pow(18)
  const { address } = useAccount()

  const pool = stakingPools.find((pool) => pool.PID === pid)!

  const response = useReadContract({
    abi: TOKENS_ABI,
    address: pool.contract,
    functionName: 'allowance',
    args: [address!, KUMA_BREEDER_CONTRACT],
  })

  const allowanceString = response.data?.toString() ?? null
  const allowanceBigNumber = allowanceString != null ? new BigNumber(allowanceString).div(decimalsBigNumber) : null

  return { allowance: allowanceBigNumber, refetchAllowance: response.refetch }
}
