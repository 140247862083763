import { motion } from 'framer-motion'

interface AnimatePromptProps {
  children: React.ReactNode // * Includes React elements, strings, numbers, fragments, portals...
  animationKey: string
  exitDuration?: number
}

export const AnimatePrompt = ({ children, animationKey, exitDuration }: AnimatePromptProps) => {
  const duration = exitDuration ?? 0.3
  return (
    <motion.div
      animate={{ y: 0, opacity: 1, transition: { type: 'tweeb', duration: 0.3 } }}
      initial={{ y: 0, opacity: 0.2 }}
      exit={{ y: 50, opacity: 0, transition: { type: 'tween', duration } }}
      transition={{ type: 'tween', ease: 'easeOut' }}
      key={animationKey}
    >
      {children}
    </motion.div>
  )
}
