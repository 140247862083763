import { KB_ABI, KUMA_BREEDER_CONTRACT } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import BigNumber from 'bignumber.js'
import { useAccount, useReadContracts } from 'wagmi'

export const useAllStakingOptionsYield = () => {
  const decimalsBigNumber = new BigNumber(10).pow(18)
  const { address } = useAccount()

  const callsList = stakingPools.map((pool) => ({
    abi: KB_ABI,
    address: KUMA_BREEDER_CONTRACT,
    functionName: 'pendingSushi',
    args: [BigInt(pool.PID), address!],
  }))

  const response = useReadContracts({
    contracts: callsList,
  })

  const yieldList = response.data ?? []

  const allStakingOptionsYieldList = stakingPools.map((pool, index) => {
    const pendingSushiString = yieldList[index]?.result != null ? yieldList[index].result.toString() : '0'
    const pendingSushiBigNumber = new BigNumber(pendingSushiString).div(decimalsBigNumber)

    return {
      pool,
      yield: pendingSushiBigNumber,
    }
  })

  const allStakingOptionsYield = allStakingOptionsYieldList.reduce(
    (acc, curr) => {
      acc[curr.pool.PID] = curr.yield
      return acc
    },
    {} as Record<number, BigNumber>
  )

  return { allStakingOptionsYield, refetchAllStakingOptionsYield: response.refetch }
}
