import { KUMA_BREEDER_CONTRACT, TOKENS_ABI } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import BigNumber from 'bignumber.js'
import { useAccount, useReadContracts } from 'wagmi'

export const useAllStakingPoolsAllowance = () => {
  const decimalsBigNumber = new BigNumber(10).pow(18)
  const { address } = useAccount()

  const allowanceCalls = stakingPools.map((pool) => ({
    abi: TOKENS_ABI,
    address: pool.contract,
    functionName: 'allowance',
    args: [address, KUMA_BREEDER_CONTRACT],
  }))

  const response = useReadContracts({
    contracts: allowanceCalls,
  })

  const allowanceResponseList = response.data ?? []

  const allStakingPoolsAllowanceList = stakingPools.map((pool, index) => {
    const allowanceString = allowanceResponseList[index]?.result?.toString() ?? null

    const allowanceBigNumber = allowanceString != null ? new BigNumber(allowanceString).div(decimalsBigNumber) : null

    return {
      pid: pool.PID,
      allowance: allowanceBigNumber,
    }
  })

  const allStakingPoolsAllowance = allStakingPoolsAllowanceList.reduce(
    (acc, curr) => {
      acc[curr.pid] = curr.allowance
      return acc
    },
    {} as Record<number, BigNumber | null>
  )

  return { allStakingPoolsAllowance, refetchAllStakingPoolsAllowance: response.refetch }
}
