import { AccountDetails } from '@/components/AccountDetails/AccountDetails'
import { useWildcardSettings } from '../use-wildcard-settings'

export const useShowAccountDetailsPrompt = () => {
  const { setWildcardSettings } = useWildcardSettings()

  const wildcardAccountDetails = {
    display: true,
    componentName: 'AccountDetails',
    Component: <AccountDetails />,
    background: 'normal',
  }

  const showAccountDetailsPrompt = () => {
    setWildcardSettings(wildcardAccountDetails)
  }

  return { showAccountDetailsPrompt }
}
