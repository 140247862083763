import { ATOM_QUERY_KEYS } from '@/constants/atom-query-keys'
import { getChainlinkEthUsdPrice } from '@/services/get-eth-usd-price/get-chainlink-eth-usd-price'
import type BigNumber from 'bignumber.js'
import { atom } from 'jotai'
import { atomEffect } from 'jotai-effect'
import { atomWithQuery } from 'jotai-tanstack-query'
import { userAddressAtom } from './wallet-provider-controller-atoms'

const chainlinkEthUsdPriceStatusAtom = atomWithQuery<BigNumber | null>((get) => ({
  queryKey: [ATOM_QUERY_KEYS.CHAINLINK_ETH_USD_PRICE],
  queryFn: getChainlinkEthUsdPrice,
  initialData: null,
  keepPreviousData: true,
  refetchInterval: 1000 * 20,
  refetchOnReconnect: true,
  refetchOnWindowFocus: true,
  refetchOnMount: true,
  refetchIntervalInBackground: true,
  staleTime: 1000 * 20,
}))

export const updateChainlinkEthUsdPriceOnWalletConnectionEffect = atomEffect((get) => {
  get(userAddressAtom)
  get.peek(chainlinkEthUsdPriceStatusAtom).refetch()
})

export const uniswapEthUsdPriceAtom = atom<BigNumber | null>(null)

export const ethUsdPriceAtom = atom((get) => {
  const chainlinkEthUsdPrice = get(chainlinkEthUsdPriceStatusAtom).data
  const uniswapEthUsdPrice = get(uniswapEthUsdPriceAtom)

  return chainlinkEthUsdPrice ?? uniswapEthUsdPrice
})
