import './assets/scss/main.scss'

import classNames from 'classnames/bind'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import styles from './App.module.scss'
import { Header } from './components/Header/Header'
import { useEthUsdPrice } from './hooks/use-eth-usd-price'
import { PoolInfoPage } from './pages/PoolInfoPage/PoolInfoPage'
import { StakingOptionsPage } from './pages/StakingOptions/StakingOptionsPage'
import { WalletConnectionHelper } from './components/wallet-connection-helper/wallet-connection-helper'
import { WildcardHandler } from './components/wildcard/wildcard-handler/wildcard-handler'

const cx = classNames.bind(styles)

function App() {
  useEthUsdPrice()

  return (
    <WalletConnectionHelper>
      <div className="App">
        <WildcardHandler />
        <Router>
          <Header />
          <div className={cx('main__wrapper')}>
            <Routes>
              <Route path="/" element={<StakingOptionsPage />} />
              <Route path="pool/:PID" element={<PoolInfoPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </Router>
      </div>
    </WalletConnectionHelper>
  )
}

export default App
