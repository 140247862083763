import type BigNumber from 'bignumber.js'
import classNames from 'classnames/bind'
import { type FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './StakingOptionsButton.module.scss'
const cx = classNames.bind(styles)

interface StakingOptionsButtonProps {
  PID: number
  image1: string
  image2?: string
  tokenName: string
  apr?: BigNumber
  totalStaked?: BigNumber
  totalStakedValue?: BigNumber
  containerStyles?: string
  cellStyles?: string
}

export const StakingOptionsButton: FC<StakingOptionsButtonProps> = ({
  PID,
  image1,
  image2,
  tokenName,
  apr,
  totalStaked,
  totalStakedValue,
  containerStyles,
  cellStyles,
}) => {
  const stylesButtonContainer = cx('button__container')
  const stylesImagesWrapper = cx('images__wrapper')
  const stylesTokenNameWrapper = cx('tokenName__wrapper')
  const stylesApyWrapper = cx('apy__wrapper')
  const stylesTotalWrapper = cx('total__wrapper')

  return (
    <Link to={`/pool/${PID}`} className={stylesButtonContainer + ' ' + containerStyles}>
      <div className={stylesImagesWrapper + ' ' + cellStyles}>
        <img className={cx('token__image')} src={image1} alt="image1" />
        {image2 && <img className={cx('eth__image')} src={image2} alt="image2" />}
      </div>
      <div className={stylesTokenNameWrapper + ' ' + cellStyles}>
        <p>{tokenName}</p>
      </div>
      <div className={stylesApyWrapper + ' ' + cellStyles}>
        <p>{apr ? apr.toFormat(1) : '--'}%</p>
      </div>
      <div className={stylesTotalWrapper + ' ' + cellStyles}>
        <p>{totalStaked ? totalStaked.toFormat(3) : '0.00'}</p>
        <p>${totalStakedValue ? totalStakedValue.toFormat(2) : '0.00'}</p>
      </div>
    </Link>
  )
}
