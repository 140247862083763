import './PromptSwapX.scss'

import { type FC, type LegacyRef } from 'react'

import { CrossCloseRound } from '../../assets/svg/any/CrossClose'

interface PromptSwapXProps {
  title: string
  titleColor?: string
  warningPrompt?: boolean
  onClickClose: () => void
  body?: JSX.Element
  bodyBackground?: string
  width?: string
  refference?: LegacyRef<HTMLDivElement> | undefined
  cover?: boolean
  hideCross?: boolean
  children?: JSX.Element
}

export const PromptSwapX: FC<PromptSwapXProps> = ({
  title,
  titleColor,
  warningPrompt,
  onClickClose,
  body,
  bodyBackground,
  width,
  refference,
  cover,
  hideCross,
  children,
}) => {
  return (
    <div
      className={`prompt ${titleColor != null ? `prompt-${titleColor} ` : ''} ${width != null ? 'prompt-' + width : ''}`}
      ref={refference}
    >
      {cover === true && <div className="prompt__cover"></div>}
      <div className={`prompt__head ${warningPrompt != null ? 'prompt__head-warning' : ''}`}>
        <h6>{title}</h6>
        {hideCross === true ? (
          ''
        ) : (
          <p className="prompt__close" onClick={onClickClose}>
            <CrossCloseRound size="2rem" />
          </p>
        )}
      </div>
      <div className={`prompt__body  ${bodyBackground != null ? 'prompt__body-' + bodyBackground : ''}`}>
        {body}
        {children}
      </div>
    </div>
  )
}
