import { userAddressAtom } from '@/state/atoms/wallet-provider-controller-atoms'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'

export const useUserAddress = () => {
  const [, setUserAddress] = useAtom(userAddressAtom)
  const { address } = useAccount()

  useEffect(() => {
    setUserAddress(address ?? null)
  }, [address])

  return { address }
}
