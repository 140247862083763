import './SelectWalletConnection.scss'

import { Button } from '../buttons/Button'
import { PromptSwapX } from '../prompts/PromptSwapX'
import { useWildcardSettings } from '@/hooks/wildcard-settings/use-wildcard-settings'
import { useAccount, useConnect } from 'wagmi'
import { useEffect } from 'react'
import { BTN_SIZES, BTN_TYPES } from '@/constants'

export const SelectWalletConnection = () => {
  const { closeWildcard } = useWildcardSettings()
  const { connectors, connect } = useConnect()
  const { address } = useAccount()

  const connectorsFiltered = connectors.filter((connector) =>
    ['metamask', 'walletconnect'].includes(connector.name.toLowerCase())
  )

  useEffect(() => {
    if (address != null) {
      closeWildcard()
    }
  }, [address])

  return (
    <div className="select_wallet_connection__prompt__cover">
      <PromptSwapX title="Connect a wallet" onClickClose={closeWildcard}>
        <div className="select_wallet_connection__prompt__body">
          {connectorsFiltered.map((connector) => (
            <section className="select_wallet_connection__prompt__body__button_container" key={connector.id}>
              <Button
                onClick={() => connect({ connector })}
                type={BTN_TYPES.SECONDARY}
                size={BTN_SIZES.MEDIUM}
                full={true}
              >
                <div className="select_wallet_connection__prompt__body__button_container__provider_container">
                  {connector.name}
                  <img
                    src={
                      connector.icon != null
                        ? connector.icon
                        : connector.name.toLowerCase() === 'metamask'
                          ? '/img/walletProviders/metamask.png'
                          : '/img/walletProviders/walletconnect.svg'
                    }
                    alt={`${connector.name} logo`}
                  />
                </div>
              </Button>
            </section>
          ))}
        </div>
      </PromptSwapX>
    </div>
  )
}
