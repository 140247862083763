import { AllowedPool } from '@/ts'
import { useAccount, useWriteContract } from 'wagmi'
import { useRefetchInfoAfterTransaction } from '../user/use-refetch-info-after-transaction'
import BigNumber from 'bignumber.js'
import { KB_ABI, KUMA_BREEDER_CONTRACT, TOKENS_ABI } from '@/constants'
import { stakingPools } from '@/constants/stakingPools'
import { useEffect } from 'react'

interface WriteContractParams {
  onSuccess?: () => void
  onError?: (e: any) => void
  onSettled?: () => void
}

export const useKumaBreeder = (PID: AllowedPool) => {
  const { refetchInfoAfterTransaction } = useRefetchInfoAfterTransaction(PID)
  const { address } = useAccount()
  const { writeContract } = useWriteContract()

  const pool = stakingPools.find((pool) => pool.PID === PID)!

  const increaseAllowance = ({ onSuccess, onError, onSettled }: WriteContractParams) => {
    if (address == null) return

    const amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    const amountBigInt = BigInt(amount)

    writeContract(
      {
        abi: TOKENS_ABI,
        address: pool.contract,
        functionName: 'approve',
        args: [KUMA_BREEDER_CONTRACT, amountBigInt],
        account: address,
      },
      {
        onSuccess: () => {
          onSuccess?.()
        },
        onError: (e) => {
          onError?.(e)
        },
        onSettled: () => {
          refetchInfoAfterTransaction()
          onSettled?.()
        },
      }
    )
  }

  const stakeUnstake =
    (action: 'deposit' | 'withdraw') =>
    (amount: BigNumber, { onSuccess, onError, onSettled }: WriteContractParams) => {
      if (address == null) return
      const decimalsBigNumber = new BigNumber(10).pow(18)

      const amountBigInt = BigInt(amount.multipliedBy(decimalsBigNumber).toFixed())

      writeContract(
        {
          abi: KB_ABI,
          address: KUMA_BREEDER_CONTRACT,
          functionName: action,
          args: [BigInt(PID), amountBigInt],
        },
        {
          onSuccess: () => {
            onSuccess?.()
          },
          onError: (e) => {
            onError?.(e)
          },
          onSettled: () => {
            refetchInfoAfterTransaction()
            onSettled?.()
          },
        }
      )
    }

  const claim = (writeContractParams: WriteContractParams) => {
    stakeUnstake('withdraw')(new BigNumber(0), writeContractParams)
  }

  return { increaseAllowance, stake: stakeUnstake('deposit'), unstake: stakeUnstake('withdraw'), claim }
}
